.player {
  display: block;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .player .plyr__volume {
    display: none !important;
  }
  
  .plyr__control {
    margin: 0 !important;
    padding: 4px !important;
  }
}

@media only screen and (max-width: 900px) {
  .plyr__control--forward {
    display: flex;
  }

  .plyr__control>span {
    margin-right: 20px;
  }

  .plyr__menu__container .plyr__control--forward:after  {
    right: -3px !important;
  }
  
  .plyr--menu-open .plyr__menu__container {
    max-height: 145px;
    overflow-y: auto;
    right: 30px;
    min-width: 135px;
    width: 100%;
  }

  .plyr__control--back span {
    margin-left: 20px;
  }

  .plyr__menu__container:after {
    content: none;
  }
}

.player .player__left {
  width: 20%;
  position: absolute;
  left: 0;
  top: 0;
  height: 70%;
  z-index: 12;
}

.player .player__right {
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
  height: 70%;
  z-index: 12;
}

.player__seeking {
  opacity: 1;
  transition: all 0.3s;
  background-color: rgba(0,0,0,0.1);
  position: relative;
}

.player__seeking.next::after {
  transform: rotate(0deg);
}

.player__seeking::after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-size: contain;
  background-image: url(../../assets/img/next-button.png);
  transform: rotate(180deg);
}