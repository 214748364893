.hls-player {
  --main-bg-color: #ba8d5b;
  --plyr-color-main: #ba8d5b;

  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.hls-player .plyr__control[data-plyr="restart"] {
  display: none;
}

.hls-player--restart .plyr__control[data-plyr="play"] {
  display: none;
}

.hls-player--restart .plyr__control--overlaid[data-plyr="play"] {
  display: none;
}

.hls-player--restart .plyr__control[data-plyr="restart"] {
  display: inline-block;
}

.plyr {
  height: 600px;
  width: 100%;
}

.plyr video, .hls-player__video {
  width: 100%;
  height: 100% !important;
  display: block;
  background-color: black;
  position: relative;
  z-index: 2;
}


.hls-player__watermark {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 1.5rem;
  left: 5%;
  opacity: .2;
  pointer-events: none;
  position: absolute;
  text-indent: initial;
  top: 5%;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
}


@media only screen and (max-width: 500px) {
  .plyr {
    height: 300px;
  }
}
