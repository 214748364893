.colhXp {
    display: none;
  }

.rdt_TableCol_Sortable div {
  overflow: visible;
}

.rdt_TableCol_Sortable span {
  width: auto !important;
  opacity: 0.5 !important;
}

  .rdt_TableHeadRow {
    font-size: 18px;
    color: #000;
  }

  #pagination-first-page, #pagination-last-page {
    display: none !important;
  }